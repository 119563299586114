import React from 'react'

interface PlayerProps {
	src: string
}

const Player = ({src}: PlayerProps) => (
	<div className="responsive-player">
		<iframe
			title={src}
			src={src}
			width="640"
			height="360"
			frameBorder="0"
			allowFullScreen
		/>
	</div>
)

interface YouTubeProps {
	id: string
}

export const YouTube = ({id}: YouTubeProps) => (
	<Player src={`https://www.youtube-nocookie.com/embed/${id}?rel=0`} />
)

interface VimeoProps {
	id: string
}

export const Vimeo = ({id}: VimeoProps) => (
	<Player src={`https://player.vimeo.com/video/${id}`} />
)