import React from 'react'
import {dashify} from '../util'

interface SectionProps {
	title?: string
	dark?: boolean
	children?: React.ReactNode
}

const Section = ({title, dark, children}: SectionProps) => (
	<section id={dashify(title || ``)} className={`section ${dark && `dark`}`}>
		{title && <h2 className="section--title">{title}</h2>}
		{children}
	</section>
)

export default Section
