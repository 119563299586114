import React from 'react'
import {graphql, PageProps} from 'gatsby'
import Helmet from 'react-helmet'
import StandardLayout from '../layouts/standard'
import Section from '../components/section'
import {Vimeo, YouTube} from '../components/video'

const VideosPage = ({data, location}: PageProps<Queries.VideosPageQuery>) => {
	const videos = data?.videos?.items?.filter(Boolean) as Contentful.Video[] ?? []

	return <StandardLayout location={location}>
		<Helmet>
			<body className="videos-page" />
		</Helmet>

		<h1 className="card-style-block-text">Videos</h1>

		<hr />

		{videos.map((video, i) => (
			<Section dark={i % 2 === 1} title={video.name}>
				<React.Fragment>
					{video.source === `Vimeo` && <Vimeo id={video.videoId} />}
					{video.source === `YouTube` && <YouTube id={video.videoId} />}
					<h3>{video.caption}</h3>
					{video.description?.description}
				</React.Fragment>
			</Section>
		))}
	</StandardLayout>
}

export default VideosPage

export const query = graphql`
	query VideosPage {
		videos: contentfulOrdering(name: {eq: "Videos Page Videos"}) {
			items {
				... on ContentfulVideo {
					name
					caption
					source
					videoId
					description {
						description
					}
				}
			}
		}
	}
`
